/*eslint-disable */
import {bus} from '@/main';
import countries from '@/services/country.json'
import { mapGetters } from 'vuex';

export default {
  name: 'doctor-info-page',
  components: {
  },
  props: [],
  data () {
    return {
      selfUserDetailData:null,
      countryList:[],
    }
  },
  computed: {
    ...mapGetters([
      'selfUserDetailGetters'
    ]),
    getSelfUserInfoMethod() {
      return this.selfUserDetailData = this.$store.getters.selfUserDetailGetters.data
    },
  },
  mounted () {
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    this.getSelfUserInfoMethod
  },
  methods: {
  },
  watch : {
    getSelfUserInfoMethod() {
      return this.$store.getters.selfUserDetailGetters;
    },
  }
}